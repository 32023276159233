<template>
	<li class="list-group-item h-100 p-0">
		<div
			class="row m-0 pl-2 alert-secondary-darker border-bottom p-1 pointer"
			@click="$emit('clicked', collapsed)">
			<div class="col my-auto align-middle">
				<div class="h5 m-0">
					<i :class="collapsed ? 'fas fa-chevron-right' : 'fas fa-chevron-down'" />
					{{ translate('package_information') }}
				</div>
			</div>
			<div class="col-auto align-middle text-center">
				<b-button
					v-if="!hideEdit"
					variant="outline-primary"
					class="btn-sm my-auto ml-auto"
					@click="$router.push({ name: 'RegisterPack' })">
					{{ translate('edit') }}
				</b-button>
			</div>
		</div>
		<template v-if="!collapsed">
			<div
				v-if="!loading"
				class="row m-2">
				<div class="col">
					<div class="row mb-2">
						<div class="col-auto">
							<p class="mb-0">
								<b v-if="packageName !== ''">{{ translate(displayedName) }}</b>
							</p>
						</div>
					</div>
					<selected-products-overview
						:products="displayedProducts"
						compact />
				</div>
			</div>
			<div
				v-if="loading"
				class="row m-2">
				<div class="col">
					<is-loading
						:loading-label="translate('loading')"
						:no-data-label="translate('empty_cart')"
						:loading="loading"
						:has-data="cartItemsHasData" />
				</div>
			</div>
		</template>
	</li>
</template>

<script>
import SelectedProductsOverview from '@/components/SelectedProductsOverview';
import CartDisplay from '@/mixins/CartDisplay';
import { SIMPLE_PRODUCTS_PARENT_PLACEHOLDER } from '@/settings/Products';
import CommonMix from '../../mixins/Common';
import { PROMO_UPGRADE_PACKAGES, NON_SPECIAL_PACKAGES } from '@/settings/Register';

export default {
	name: 'PackageOverview',
	components: { SelectedProductsOverview },
	mixins: [CartDisplay, CommonMix],
	props: {
		stepNo: {
			type: Number,
			default: 1,
		},
		hideEdit: {
			type: Boolean,
			default: false,
		},
		collapsed: {
			type: Boolean,
			default: true,
		},
		canUpgradePackage: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			packageName: '',
			specialPackName: '',
			isPack: true,
			promoUpgradePackages: PROMO_UPGRADE_PACKAGES,
		};
	},
	computed: {
		displayedProducts() {
			return this.cartItems.reduce((accumulator, item) => {
				const parentCodeName = item.parent.code_name || SIMPLE_PRODUCTS_PARENT_PLACEHOLDER;

				accumulator[parentCodeName] = {
					...accumulator[parentCodeName],
					[item.code_name]: {
						qty: item.qty,
						sku: item.sku,
					},
				};

				return accumulator;
			}, {});
		},
		displayedName() {
			if (NON_SPECIAL_PACKAGES.includes(this.packageName)) {
				return this.packageName;
			}

			return this.isPack && this.canUpgradePackage ? this.promoUpgradePackages[this.packageName] : this.packageName;
		},
	},
	created() {
		const packageInformation = this.getStepInformation('RegisterPack');
		this.isPack = packageInformation.product.is_pack ?? false;
		if (typeof packageInformation !== 'undefined' && typeof packageInformation.product !== 'undefined') {
			const { package_code_name: packageName } = packageInformation.product;
			this.packageName = packageName;
		}
	},
};
</script>
