<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div class="row">
			<div class="col-12">
				<div
					v-if="pickupOffices.length"
					class="p-3">
					<label
						for="shipping_type"
						class="label font-weight-bold">{{ translate('shipping_type') }}
						<span class="text-danger">*</span>
					</label>
					<b-form-radio-group
						v-model="shippingType"
						stacked>
						<b-form-radio
							v-for="(item, index) in shippingTypes"
							:key="index"
							:value="item"
							:name="`${currentValidation}.shipping_type`">
							{{ translate(item) }}
						</b-form-radio>
					</b-form-radio-group>
				</div>
				<div
					v-if="shippingType === 'pickup_at_office'"
					class="pt-0 pb-3 pl-3 pr-3">
					<div class="row">
						<div class="col-12">
							<div class="h4 mb-2">
								{{ translate('address_information') }}
							</div>
						</div>
					</div>
					<b-form-radio-group
						v-model="pickupAtOffice"
						stacked>
						<b-form-radio
							v-for="pickupOffice in pickupOffices"
							:key="pickupOffice.warehouse_id"
							:value="pickupOffice.warehouse_id"
							:name="`${currentValidation}.pickup_office`">
							<b>{{ pickupOffice.address.address }}, {{ pickupOffice.address.city }}, {{ pickupOffice.address.state }}, {{ pickupOffice.address.zip }}, {{ pickupOffice.address.country }}</b>
						</b-form-radio>
					</b-form-radio-group>
				</div>
				<form
					v-if="shippingType === 'send_to_address' || !pickupOffices.length"
					@submit.prevent="prepareForStorage()"
					@keydown="clearValidationError($event.target.name);"
					@change="clearValidationError($event.target.name);">
					<li class="list-group-item border-0 rounded-0 pb-0">
						<div class="row">
							<div class="col-12">
								<div class="h4 mb-2">
									{{ translate('address_information') }}
								</div>
							</div>
						</div>
						<address-country-form
							:address="ADDRESSES.shipping"
							:user-country="true"
							:form-import.sync="form"
							:errors-import="validationErrors"
							:prefix-errors="`${currentValidation}.shipping_address.`"
							:country-code-import="registerCountry"
							:display-cancel-button="false"
							:display-submit-button="false"
							:display-goback-button="false"
							:display-override-toggle="showOverrideValidation" />
					</li>
				</form>
			</div>
		</div>
		<li
			v-if="!isJns() && products.length > 0 && !earlyLaunch"
			class="list-group-item border-left-0 border-right-0 border-bottom-0 rounded-0 pb-0">
			<div class="row no-gutters d-flex align-items-end">
				<div class="col-auto mr-3">
					<div class="font-weight-bold">
						<div class="custom-control custom-checkbox my-auto">
							<input
								id="discount"
								v-model="addDiscount"
								type="checkbox"
								class="custom-control-input">
							<label
								for="discount"
								class="custom-control-label h4">
								{{ translate('enable_monthly_autoship') }}
							</label>
						</div>
					</div>
				</div>
				<div
					:class="{ 'py-2': ['xs', 'sm'].includes(windowWidth) }"
					class="col-12 col-md">
					<button
						v-if="addDiscount"
						:class="['xs', 'sm'].includes(windowWidth) ? 'w-100 my-2' : ''"
						class="btn btn-link p-0 border-0"
						@click="openAutoshipModal = true">
						{{ translate('choose_monthly_autoship') }}
					</button>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<p class="mt-2 mb-3">
						{{ translate('autoship_description') }}
					</p>
				</div>
			</div>
			<div
				v-if="addDiscount"
				class="row my-2">
				<div
					v-if="loadingProducts"
					class="col-auto text-center mt-2">
					<div class="h5">
						<em class="fa fa-fw fa-spinner fa-pulse" />
						<span>
							{{ translate('loading') }}
						</span>
					</div>
				</div>
				<div
					v-else
					class="col-auto">
					<selected-products-overview :products="displayedProducts" />
				</div>
			</div>
			<template v-if="(typeof validationErrors[`${currentValidation}.autoship.products`] !== 'undefined')">
				<b-alert
					class="mt-3"
					variant="warning"
					show>
					<span
						v-for="error in validationErrors[`${currentValidation}.autoship.products`]"
						:key="error">
						<i class="fa fa-exclamation-circle" />
						{{ error }} <br>
					</span>
				</b-alert>
			</template>
		</li>
		<li class="list-group-item border-0 rounded-0 pt-0 pb-3">
			<div class="row no-gutters">
				<div class="col">
					<div
						v-if="addDiscount"
						class="row d-flex justify-content-end">
						<div class="col col-md-12 my-2 text-left">
							<div class="form-check">
								<input
									id="autoshipAgreement"
									v-model="autoshipAgreement"
									name="autoshipAgreement"
									class="form-check-input"
									type="checkbox">
								<label
									class="form-check-label"
									for="autoshipAgreement"
									v-html="translate('autoship_agreement')" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row no-gutters justify-content-end">
				<!-- <div
					v-if="currentStep !== 1"
					:class="{ 'pr-2': currentStep !== 1 }"
					class="col">
					<b-button
						variant="secondary"
						size="lg"
						:style="!['xs'].includes(windowWidth) ? 'min-width: 150px;' : ''"
						:class="{ 'w-100': ['xs'].includes(windowWidth) }"
						class="float-md-right"
						@click="$emit('cancel')">
						{{ translate('cancel') }}
					</b-button>
				</div> -->
				<div
					:style="['xs'].includes(windowWidth) ? '' : 'min-width: 200px;'"
					:class="currentStep !== 1 ? 'col-6' : 'col-12'"
					class="col col-md-3">
					<b-button
						:disabled="validating || !!preparing || (addDiscount && !autoshipAgreement) || !validSelection"
						variant="primary"
						size="lg"
						:style="currentStep !== 1 ? '' : 'min-width: 200px;'"
						class="w-100"
						@click="handlePickupAtOffice()">
						<i
							v-if="validating"
							class="fas fa-spinner fa-spin mr-2" />{{ translate('continue') }}
					</b-button>
				</div>
			</div>
		</li>
		<add-autoship-modal
			v-show="!loadingProducts && addDiscount"
			:loading-products="loadingProducts && addDiscount"
			:autoship-products="autoshipProducts"
			:products="products"
			:open="openAutoshipModal"
			:title="translate('choose_monthly_autoship')"
			@cancel="handleCancel"
			@confirm="handleConfirm" />
	</div>
</template>
<script>
import AddAutoshipModal from '@/components/AddAutoshipModal';
import SelectedProductsOverview from '@/components/SelectedProductsOverview';
import { ALLOW_CHANGE_COUNTRY_TO_USER as allowAnotherCountry, ADDRESSES } from '@/settings/AddressBook';
import {
	FORBIDDEN, NOT_FOUND, UNPROCESSABLE,
} from '@/settings/Errors';
import { SIMPLE_PRODUCTS_PARENT_PLACEHOLDER, SORTED_PRODUCT_SKUS as sortList } from '@/settings/Products';
import {
	ADDRESS_VERIFICATION_COUNTRIES as addressVerificationCountries,
	// SHOW_SIGNATURES_BY_COUNTRY as countriesListForSignature,
} from '@/settings/Shipping';
import ConfigurableProducts from '@/mixins/ConfigurableProducts';
import WindowSizes from '@/mixins/WindowSizes';
import AddressBook from '@/util/AddressBook';
import Products from '@/util/Products';
import Country from '@/util/Country';
import CommonMix from '../../mixins/Common';
import Steps from '../../mixins/Steps';
import EarlyLaunch from '@/mixins/EarlyLaunch';
import AddressCountryForm from '@/components/AddressCountryForm';
import EventBus from '@/util/eventBus';

export default {
	name: 'ShippingEdit',
	components: {
		AddressCountryForm,
		AddAutoshipModal,
		SelectedProductsOverview,
	},
	mixins: [CommonMix, ConfigurableProducts, Steps, WindowSizes, EarlyLaunch],
	props: {
		pickupOffices: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			countryData: new Country(),
			stateData: new Country(),
			sponsor: 0,
			identityId: null,
			form: {
				first_name: '',
				last_name: '',
				mobile_number: '',
				address: '',
				address2: '',
				address3: '',
				address4: '',
				postcode: '',
				city: '',
				country_code: '',
				region_id: '',
				region_name: '',
				signature_required: false,
				override_validation: false,
				personal_document_number: '',
				personal_document_front_file: undefined,
				personal_document_back_file: undefined,
			},
			allowAnotherCountry,
			alert: new this.$Alert(),
			getSuggestion: new AddressBook(),
			// countriesListForSignature,
			showOverrideValidation: false,
			addressVerificationCountries,
			addDiscount: false,
			autoshipAgreement: false,
			autoshipProducts: {},
			openAutoshipModal: false,
			prodData: new Products(),
			ADDRESSES,
			shippingType: 'send_to_address',
			shippingTypes: ['send_to_address', 'pickup_at_office'],
			pickupAtOffice: null,
		};
	},
	computed: {
		validSelection() {
			if (!this.shippingTypes.includes(this.shippingType)) {
				return false;
			}
			if (this.pickupOffices.length > 0 && this.shippingType === 'pickup_at_office') {
				return this.pickupAtOffice !== null;
			}
			return true;
		},
		products() {
			try {
				const productsData = this.prodData.data.response.data.data
					.filter((product) => !!product.attributes.available_for_autoship)
					.sort((a, b) => {
						const aIndex = sortList.indexOf(a.attributes.sku);
						const bIndex = sortList.indexOf(b.attributes.sku);

						if (aIndex === -1) return 1;
						if (bIndex === -1) return -1;

						return aIndex - bIndex;
					});

				return productsData;
			} catch (error) {
				return [];
			}
		},
		loadingProducts() {
			try {
				return this.prodData.data.loading;
			} catch (error) {
				return true;
			}
		},
		countries() {
			try {
				const response = this.countryData.data.response.data.data;
				if (allowAnotherCountry === false) {
					return response.filter((item) => item.attributes.code === this.registerCountry);
				}
				return response;
			} catch (error) {
				return [];
			}
		},
		hasCountries() {
			return !!this.countries.length;
		},
		states() {
			try {
				return this.stateData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		hasState() {
			return !!this.states.length;
		},
		savedDiscount() {
			const { discount } = { ...this.getStepInformation('RegisterShipping') };
			return discount;
		},
		savedShipping() {
			const { shipping } = { ...this.getStepInformation('RegisterShipping') };
			return shipping;
		},
		displayedProducts() {
			return Object.entries(this.autoshipProducts).reduce((accumulator, [sku, item]) => {
				const parentCodeName = item.parent_code_name || SIMPLE_PRODUCTS_PARENT_PLACEHOLDER;

				if (item.value !== 0) {
					accumulator[parentCodeName] = {
						...accumulator[parentCodeName],
						[item.code_name]: {
							qty: item.value,
							sku,
						},
					};
				}

				return accumulator;
			}, {});
		},
	},
	watch: {
		autoshipProducts(value) {
			this.createWatchesForProducts(value);
		},
		addDiscount(value) {
			if (!value) {
				// eslint-disable-next-line no-restricted-syntax,guard-for-in
				for (const sku in this.autoshipProducts) {
					this.autoshipProducts[sku].value = 0;
				}
			}
		},
	},
	mounted() {
		const replicatedUsername = this.$replicated.replicatedId();
		this.countryData.getCountries({ replicated_site: replicatedUsername });
		this.getGeneralInformation();
		this.initializeValues();
		this.getStoredInformation();
		this.$watch('addDiscount', (newVal) => {
			if (newVal) {
				this.openAutoshipModal = true;
			}
		});
	},
	methods: {
		disableAutoshipIfNoProducts() {
			// If the user does not select any product, set checkbox to false
			if (Object.values(this.autoshipProducts).every((product) => product.value === 0)) {
				this.addDiscount = false;
			}
		},
		handleConfirm(selectedProducts) {
			this.openAutoshipModal = false;
			this.autoshipProducts = { ...selectedProducts };
			this.disableAutoshipIfNoProducts();
		},
		handleCancel() {
			this.openAutoshipModal = false;
			this.disableAutoshipIfNoProducts();
		},
		initializeValues() {
			this.form.country_code = this.registerCountry;
			this.stateData.getStates(this.form.country_code);
		},
		initializeAutoship() {
			this.prodData.getProducts(this.getRegisterCountry(), undefined, { autoship: true, formatAutoship: true, sponsor: this.$replicated.replicatedSponsor() }).then(() => {
				// Flatten configurable products
				const products = this.products.reduce((accumulator, product) => {
					if (!product.attributes.has_configurations) {
						accumulator.push({
							sku: product.attributes.sku,
							code_name: product.attributes.code_name,
							parent_sku: null,
							parent_code_name: null,
						});
					} else {
						Object.keys(this.getProductConfigs(product)).forEach((config) => {
							const configuredProduct = this.getConfiguredProduct(product, config);

							accumulator.push({
								sku: configuredProduct.sku,
								code_name: configuredProduct.code_name,
								parent_sku: product.attributes.sku,
								parent_code_name: product.attributes.code_name,
							});
						});
					}

					return accumulator;
				}, []);

				// Load saved autoship products or fill in products equally
				// until reaching AUTOSHIP_MIN_QTY of total autoship product count
				if (!this.savedShipping?.autoship?.products || this.savedShipping?.autoship?.products.length === 0) {
					for (let index = 0; index < products.length; index += 1) {
						const product = products[index];
						const { sku } = product;
						const currentAutoshipProduct = this.autoshipProducts[sku];

						this.$set(this.autoshipProducts, sku, {
							...product,
							value: currentAutoshipProduct ? currentAutoshipProduct.value += 0 : 0,
							required: currentAutoshipProduct ? currentAutoshipProduct.required : false,
						});
					}
				} else {
					products.forEach((product) => {
						const { sku } = product;
						if (typeof this.savedShipping.autoship.products[sku] !== 'undefined') {
							this.$set(this.autoshipProducts, sku, {
								...product,
								value: this.savedShipping.autoship.products[sku],
								required: true,
							});
						} else {
							this.$set(this.autoshipProducts, sku, {
								...product,
								value: 0,
								required: false,
							});
						}
					});
				}
			});
			return null;
		},
		createWatchesForProducts(value) {
			Object.keys(value).forEach((item) => {
				this.$watch(() => this.autoshipProducts[item].required, (newVal) => {
					this.clearValidationError(`${this.currentValidation}.autoship.products`);
					if (newVal) {
						if (this.autoshipProducts[item].value === 0) {
							this.autoshipProducts[item].value = 1;
						}
					} else {
						this.autoshipProducts[item].value = 0;
					}
				});
				this.$watch(() => this.autoshipProducts[item].value, (newVal) => {
					this.clearValidationError(`${this.currentValidation}.autoship.products`);
					if (newVal > 0) {
						this.autoshipProducts[item].required = true;
					} else {
						this.autoshipProducts[item].required = false;
					}
				});
			});
		},
		getGeneralInformation() {
			const personalInfo = this.getStepInformation('RegisterPersonalInformation');
			const {
				sponsor, personal_information: personalInformation, identity_id: identityId,
			} = personalInfo;
			if (typeof sponsor === 'undefined' || typeof personalInformation === 'undefined') {
				this.goToStep(1);
				return null;
			}
			const fieldsToCopy = ['first_name', 'last_name', 'mobile_number'];
			fieldsToCopy.forEach((field) => {
				if (typeof personalInformation[field] === 'string') {
					this.form[field] = personalInformation[field];
				}
			});
			this.initializeAutoship();
			this.identityId = identityId;
			this.sponsor = sponsor;
			return null;
		},
		getStoredInformation() {
			try {
				const { shipping_address: data, pickup_at_office: pickupAtOffice } = this.savedShipping;
				this.pickupAtOffice = pickupAtOffice;
				this.shippingType = pickupAtOffice ? 'pickup_at_office' : 'send_to_address';
				const { autoship } = this.savedShipping;
				if (!autoship) this.addDiscount = false;
				else this.addDiscount = this.savedDiscount;
				const { country_code: countryCode } = data;
				Object.keys(this.form).forEach((key) => {
					if (typeof data[key] !== 'undefined') {
						this.form[key] = data[key];
						if (key === 'override_validation') {
							this.showOverrideValidation = true;
						}
					}
				});
				if (countryCode !== this.registerCountry) {
					this.form.region_id = '';
				}
			} catch (error) {
				//
			}
			return null;
		},
		handlePickupAtOffice() {
			if (this.shippingType === 'pickup_at_office') {
				const alert = new this.$Alert();
				const office = this.pickupOffices.find((pickupOffice) => pickupOffice.warehouse_id === this.pickupAtOffice);
				const address = `<b>${office.address.address}, ${office.address.city}, ${office.address.state}, ${office.address.zip}, ${office.address.country}</b>`;
				alert.confirmation(this.translate('pickup_at_office_confirmation_title'), this.translate('pickup_at_office_confirmation_text', { address }), {
					confirmButtonText: this.translate('yes'),
					cancelButtonText: this.translate('no_word'),
					config: {
						icon: 'warning',
						allowOutsideClick: false,
						allowEscapeKey: false,
						reverseButtons: true,
						showCancelButton: true,
					},
				}, true).then(() => {
					this.prepareForStorage();
				}).catch(() => {});
			} else {
				this.prepareForStorage();
			}
		},
		prepareForStorage() {
			EventBus.$emit('callAddressRequestData');
			this.preparing = true;
			if (this.shippingType === 'pickup_at_office') {
				const office = this.pickupOffices.find((pickupOffice) => pickupOffice.warehouse_id === this.pickupAtOffice);
				this.form.address = office.address.address;
				this.form.address2 = office.address.address2 || '';
				this.form.address3 = office.address.address3 || '';
				this.form.address4 = office.address.address4 || '';
				this.form.postcode = office.address.zip;
				this.form.city = office.address.city;
				this.form.country_code = office.address.country;
				this.form.region_id = office.address.region_id;
				this.form.region_name = office.address.state;
			}
			const shipping = {
				pickup_at_office: this.pickupAtOffice,
				shipping_address: { ...this.form },
			};
			if (this.addDiscount) {
				let autoship = {};
				if (Object.keys(this.autoshipProducts).length > 0) {
					Object.keys(this.autoshipProducts).forEach((item) => {
						const product = {};
						if (this.autoshipProducts[item].required === true) {
							product[item] = this.autoshipProducts[item].value;
							autoship = { ...autoship, ...product };
						}
					});
				}
				if (Object.keys(autoship).length > 0) {
					shipping.autoship = { products: { ...autoship } };
				}
			}
			let payload = {
				step: this.currentValidation,
				sponsor: this.sponsor,
				shipping,
				identity_id: this.identityId,
				discount: this.addDiscount,
			};
			if (!this.showOverrideValidation) {
				payload.shipping.shipping_address.override_validation = undefined;
			}
			this.validateStep(payload, true).then((response) => {
				payload = { ...payload, shipping_data: { ...response.response } };
				this.saveStep(payload);
			}).catch((errors) => {
				if ([...NOT_FOUND, ...FORBIDDEN].includes(errors.status)) {
					this.$emit('invalidRequest', errors);
				}
				if (UNPROCESSABLE.includes(errors.status)) {
					const { cart_id: cartId } = errors.errors;
					if (typeof cartId !== 'undefined') {
						let response = '';
						cartId.forEach((item) => { response += `${item} \n`; });
						this.alert.toast('error', response, { timer: 6000 });
						setTimeout(() => {
							this.$emit('cartValidationError');
						}, 6000);
					}

					Object.keys(errors.errors).forEach((errorKey) => {
						if (errorKey === 'shipping.shipping_address.suggestion') {
							this.getSuggestion.getSuggestion(this.form).then((suggestionResponse) => {
								let address = '';
								Object.keys(suggestionResponse.response.address).forEach((item, index) => {
									if (suggestionResponse.response.address[item] !== '' && item !== 'region_id') {
										address += `${suggestionResponse.response.address[item]}`;
										if (index < (Object.keys(suggestionResponse.response.address).length) - 2) {
											address += ', ';
										}
									}
								});
								const trans = {
									title: this.translate('invalid_address'),
									text: this.translate('suggestion_address', { address }),
								};
								const options = {
									config: {
										icon: 'warning',
										showCancelButton: true,
										confirmButtonText: this.translate('accept_sugestion'),
										cancelButtonText: this.translate('manual_change'),
										reverseButtons: true,
										allowOutsideClick: false,
										allowEscapeKey: false,
									},
								};
								this.alert.confirmation(trans.title, trans.text, options, true).then(() => {
									Object.keys(suggestionResponse.response.address).forEach((suggestionItem) => {
										Object.keys(this.form).forEach((formItem) => {
											if (formItem === suggestionItem) {
												this.form[formItem] = suggestionResponse.response.address[suggestionItem];
											}
										});
									});
								}).catch(() => {});
							});
						} else if (errorKey.includes('shipping.shipping_address.address') && !!this.form.address.trim().length && this.addressVerificationCountries.includes(this.form.country_code)) {
							this.showOverrideValidation = true;
						}
					});
				}
				this.preparing = false;
			});
			return null;
		},
	},
};
</script>
