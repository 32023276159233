<template>
	<cart-totals
		:class="{ 'outline': isLastStep }"
		:loading="cartLoading"
		:totals="cartTotals"
		:small-text-totals="cartSmallTextTotals"
		:cart-items="cartItems"
		:total="cartTotal"
		:total-volume="totalVolume"
		:disable-action="!disclaimer || !isLastStep || !cartItems.length"
		:exchange="exchange"
		:discount-detail="discountDetail"
		action-classes="btn-lg"
		@action="handleJoinToVClub">
		<div
			v-if="showSponsor || hasInitialSponsor"
			class="row mb-3">
			<div class="col">
				<sponsor-overview />
			</div>
		</div>
		<div class="row">
			<div class="col">
				<disclaimer
					:checked="disclaimer"
					@change="disclaimer = $event" />
			</div>
		</div>
	</cart-totals>
</template>
<script>
import CartTotals from '@/components/Cart/CartTotals';
import {
	FORBIDDEN, NOT_FOUND, UNPROCESSABLE, LOCKED, INTERNAL_SERVER_ERROR,
} from '@/settings/Errors';
import Disclaimer from './Disclaimer';
import SponsorOverview from './SponsorOverview';
import CommonMix from '../../mixins/Common';
import SponsorMix from '../../mixins/Sponsor';
import { PRE_CONFIRMATION_NOTE_COUNTRIES } from '@/settings/Country';
import { S3_PATH } from '@/settings/Images';
import { TIMEZONE_NAME } from '@/settings/General';

export default {
	name: 'OrderSummary',
	components: {
		CartTotals,
		Disclaimer,
		SponsorOverview,
	},
	mixins: [CommonMix, SponsorMix],
	props: {
		cartLoading: {
			type: Boolean,
			default: false,
		},
		cartItems: {
			type: Array,
			default: () => [],
		},
		cartTotals: {
			type: Object,
			default: () => ({}),
		},
		cartSmallTextTotals: {
			type: Array,
			default: () => [],
		},
		cartTotal: {
			type: String,
			default: '',
		},
		totalVolume: {
			type: String,
			default: '',
		},
		exchange: {
			type: String,
			default: '',
		},
		showSponsor: {
			type: Boolean,
			default: false,
		},
		hasTickets: {
			type: Boolean,
			default: false,
		},
		ticketsInfo: {
			type: Object,
			default: () => ({}),
		},
		discountDetail: {
			type: Array,
			default: () => [],
		},
		packageCodeName: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			alert: new this.$Alert(),
			disclaimer: false,
			hasInitialSponsor: false,
			idempotencyId: '',
			idempotencyId2: '',
			paymentMethod: {
				name: '',
			},
			joinToVClubPackages: ['ambassador', 'ambassador_plus'],
			joinToVClub: false,
			themeName: process.env.VUE_APP_THEME,
		};
	},
	computed: {
		canJoinToVClub() {
			return this.joinToVClubPackages.includes(this.packageCodeName) && !this.isJns();
		},
	},
	watch: {
		currentValidation() {
			this.getStartingInfo();
		},
		'sponsorInfo.id': function sponsor(newVal) {
			this.hasInitialSponsor = newVal !== '';
		},
	},
	mounted() {
		this.getStartingInfo();
		this.refreshSponsorData();
	},
	methods: {
		getStartingInfo() {
			this.idempotencyId = this.createUuid();
			this.idempotencyId2 = this.createUuid();
			this.stepNames.forEach((stepName) => {
				if (stepName === 'RegisterConfirmation') {
					return null;
				}

				const stepInfo = this.getStepInformation(stepName);
				if (stepName === 'RegisterPersonalInformation') {
					const { sponsor, personal_information: personalInformation } = stepInfo;
					if (typeof personalInformation === 'undefined' || typeof sponsor === 'undefined') {
						return null;
					}

					if (sponsor.id !== this.sponsorInfo.id) {
						this.refreshSponsorData();
					}
				} else if (stepName === 'RegisterPayment') {
					const paymentMethod = { ...this.getStepInformation('RegisterPayment').card_data };
					if (Object.keys(paymentMethod).length) {
						this.paymentMethod.name = stepInfo.payment.payment_method.name;
					}
				}

				return null;
			});
		},
		handleJoinToVClub() {
			const paymentDate = this.$moment()
				.tz(TIMEZONE_NAME)
				.add(4, 'weeks')
				.subtract(5, 'days')
				.format('MMMM Do');

			if (this.canJoinToVClub) {
				const alertConfig = {
					icon: undefined,
					title: this.translate('v_club_register_introducing_title'),
					text: '',
					html: this.translate('v_club_register_introducing_text', { payment_date: paymentDate }),
					showCancelButton: true,
					confirmButtonText: this.translate('i_want_it'),
					cancelButtonText: this.translate('i_dont_want_to_save_money'),
					reverseButtons: true,
					imageUrl: `${S3_PATH}/assets/products/${this.themeName}/v_club_membership.png?v=1`,
					imageWidth: '130px',
					customClass: {
						image: 'my-0',
					},
				};
				this.alert.preConfirm(alertConfig, () => {}, (value) => {
					this.joinToVClub = !!value.value;
				}).finally(() => {
					this.goSuccess();
				});
			} else {
				this.goSuccess();
			}
		},
		goSuccess() {
			if (PRE_CONFIRMATION_NOTE_COUNTRIES.includes(this.getRegisterCountry())) {
				const alertConfig = {
					icon: 'info',
					title: this.translate('note_title'),
					text: this.translate(`${this.getRegisterCountry().toLowerCase()}_note_text`),
					showCancelButton: true,
					confirmButtonText: this.translate('confirm_note_i_agree'),
					reverseButtons: true,
				};
				this.alert.preConfirm(alertConfig, () => {
					this.goSuccessValidated();
				}, () => {});
			} else {
				this.goSuccessValidated();
			}
		},
		goSuccessValidated() {
			let payload = {};
			if (this.canJoinToVClub) {
				payload.join_to_v_club = this.joinToVClub;
			}
			this.stepNames.forEach((stepName) => {
				if (stepName !== 'RegisterConfirmation') {
					payload = { ...payload, ...this.getStepInformation(stepName) };
					if (stepName === 'RegisterPersonalInformation') {
						payload.sponsor = this.sponsorInfo.id;
					} else if (stepName === 'RegisterPayment') {
						payload.payment.payment_method.idempotency_id = this.idempotencyId;
						payload.payment.payment_method.idempotency_id_2 = this.idempotencyId2;
					}
				}
			});

			if (this.hasTickets) {
				payload.tickets = this.ticketsInfo;
			}

			const options = {
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			};
			this.alert.loading(this.translate('processing_request'), this.translate('white_util_checkout'), options);
			const validation = { ...payload, step: this.getStepValidationByName('RegisterConfirmation') };
			this.validateStep(validation, true).then((response) => {
				this.removeInfo(false, false).then(() => {
					this.$store.dispatch('position/removeRegister');

					const {
						redirect_url: redirectUrl,
						verification_required: verificationRequired,
						crypto_wallet: cryptoWallet,
					} = response.response;
					// Redirect to instructed page
					if (redirectUrl) {
						window.location.href = redirectUrl;
						return;
					}
					// Pass payment method to success page in case we need to display an alert for it
					const routeOptions = {
						name: 'RegisterSuccess',
						query: { payment_method: payload.payment.payment_method.name ?? '' },
					};
					// Show verbal verification alert
					if (verificationRequired) {
						routeOptions.query.verification_required = true;
					}
					// Go to crypto payment details
					if (cryptoWallet) {
						routeOptions.query = { wallet_address: cryptoWallet };
					}
					// Go to new route
					this.$router.replace(routeOptions);
					// Close 'Processing request...' alert
					this.alert.close();
				}).catch(() => {});
			}).catch((error) => {
				this.idempotencyId = this.createUuid();
				this.idempotencyId2 = this.createUuid();
				if ([...NOT_FOUND, ...FORBIDDEN].includes(error.status)) {
					this.$emit('invalidRequest', error);
				}
				if (UNPROCESSABLE.includes(error.status)) {
					const {
						cart_id: cartId,
						leg,
						payex,
						'payment.payment_method.name': paymentMethodName,
						tickets,
					} = error.errors;

					let personalInfoErrors;

					Object.keys(error.errors).forEach((key) => {
						if (key.includes('personal_information')) {
							if (typeof personalInfoErrors === 'undefined') {
								personalInfoErrors = [];
							}
							const value = JSON.parse(JSON.stringify(error.errors[key]));
							personalInfoErrors = personalInfoErrors.concat(value);
						}
					});

					if (typeof cartId !== 'undefined') {
						if (typeof cartId === 'string' || typeof cartId === 'number') {
							this.saveCart(cartId);
						} else {
							let response = '';
							cartId.forEach((item) => { response += `${item} \n`; });
							this.alert.toast('error', response, { timer: 6000 });
							setTimeout(() => {
								this.$emit('cartValidationError');
							}, 6000);
						}
					}
					if (typeof leg !== 'undefined') {
						const alertConfig = {
							icon: 'error',
							title: this.translate('something_went_wrong'),
							text: this.translate('invalid_position'),
							showCancelButton: false,
						};
						this.alert.preConfirm(alertConfig, () => {}, () => {
							this.$emit('redirectTo', 'BinaryTree');
						});
					}
					if (typeof payex !== 'undefined') {
						let response = '';
						payex.forEach((item) => { response += `${item} <br>`; });
						this.alert.error(this.translate('something_went_wrong'), response, true);
					}
					if (typeof paymentMethodName !== 'undefined') {
						let response = '';
						paymentMethodName.forEach((item) => { response += `${item} \n`; });
						this.alert.toast('error', response, { timer: 6000 });
					}
					if (typeof tickets !== 'undefined') {
						this.alert.toast('error', this.translate('event_attendees_validation'), { timer: 6000 });
					}
					if (typeof personalInfoErrors !== 'undefined') {
						let response = '';
						personalInfoErrors.forEach((item) => { response += `${item} <br>`; });
						this.alert.error(this.translate('something_went_wrong'), response, true);
					}

					// If the validation error wasn't one of the above
					if ([cartId, leg, payex, paymentMethodName, tickets, personalInfoErrors].every((field) => typeof field === 'undefined')) {
						this.alert.toast('error', this.translate('something_went_wrong'), { timer: 6000 });
					}
				}
				if (INTERNAL_SERVER_ERROR.includes(error.status)) {
					this.alert.toast('error', this.translate('something_went_wrong'), { timer: 2500 });
					setTimeout(() => { this.$router.replace({ name: 'RegisterPack' }); }, 2500);
				}
				if (LOCKED.includes(error.status)) {
					const alertConfig = {
						icon: 'error',
						title: this.translate('something_went_wrong'),
						text: this.translate('invalid_position'),
						showCancelButton: false,
					};
					this.alert.preConfirm(alertConfig, () => {}, () => {
						this.$emit('redirectTo', 'BinaryTree');
					});
				}
			});
			return null;
		},
		refreshSponsorData() {
			this.getSponsorData();
		},
	},
};
</script>
<style scoped>
.outline {
	outline: 0;
	border-radius: 0.3rem;
	-webkit-box-shadow: 0 0 0 0.2rem #eb772f40;
	box-shadow: 0 0 0 0.2rem #eb772f40; /* eb772f40 */
}
</style>
