<template>
	<div class="row">
		<div class="col d-flex justify-content-center">
			<div
				v-if="!loading"
				class="alert alert-info text-center h5 w-100 mb-0">
				<div>
					<span>{{ translate('selected_sponsor') }}: </span><br>
					<span class="font-weight-bold">{{ sponsorInfo.name }} </span><br>
					<!-- <span class="font-weight-bold">({{ translate('id_word') }}: {{ sponsorInfo.id }})</span> -->
				</div>
				<template v-if="Object.keys(position).length">
					<hr>
					<div>
						<span>{{ translate('selected_parent') }}: </span><br>
						<template v-if="!position.spillover && position.parent">
							<span class="font-weight-bold">{{ position.parent }} </span><br>
						</template>
						<span class="font-weight-bold">{{ translate('leg') }}: {{ translate(leg) }}</span>
					</div>
				</template>
			</div>
			<is-loading
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				:loading="loading"
				:has-data="!loading" />
		</div>
	</div>
</template>
<script>
import IsLoading from '@/components/Loading';
import CommonMix from '../../mixins/Common';
import SponsorMix from '../../mixins/Sponsor';
import { REPLICATED_LEGS_LINK } from '@/settings/Register';

export default {
	name: 'SponsorOverview',
	components: { IsLoading },
	mixins: [CommonMix, SponsorMix],
	data() {
		return {
			position: {},
		};
	},
	computed: {
		loading() {
			return this.sponsorInfo.name === '';
		},
		leg() {
			return this.position.placement !== undefined ? this.position.placement : this.position.leg;
		},
	},
	async mounted() {
		this.getSponsorData();

		if (REPLICATED_LEGS_LINK.includes(this.$replicated.replicatedPage())) {
			let position = this.getStepInformation('position');

			if (position === '') {
				const replicatedPage = this.$replicated.replicatedPage();
				await this.updateCustomRegisterLeg(replicatedPage);
			}

			position = this.getStepInformation('position');
			if (position !== '') {
				this.position = position;
			}
		} else {
			this.position = this.getRegisterPositionValues();
		}
	},
};
</script>
